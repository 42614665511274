<template>

<div id="app" class="layout-wrapper layout-content-navbar">
    <div class="layout-container">
      <!-- <side></side> -->
      <!-- <div class="layout-page"> -->
        <!-- <Nav></Nav> -->
        <!-- <div class="content-wrapper"> -->
          <!-- <Transition name=""> -->

            <router-view/>
          <!-- </Transition> -->

        <!-- </div> -->


      <!-- </div> -->


    </div>

<!-- Overlay -->
<div class="layout-overlay layout-menu-toggle"></div>

</div>
  <!-- <div id="app">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
  
  </div> -->
</template>

<script>
import Nav from "./components/layout/Nav.vue";
import side from "./components/layout/Side.vue";
 export default {
  components: {
    Nav,
    side,
  },

//   beforeDestroy () {
//   localStorage.removeItem('token');
// }
}

</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.footer { 
    position: absolute; 
    bottom: 0; 
    left: 0; 
    z-index: 10;
}
</style>

import { render, staticRenderFns } from "./Side.vue?vue&type=template&id=bd4e4f80&scoped=true"
import script from "./Side.vue?vue&type=script&lang=js"
export * from "./Side.vue?vue&type=script&lang=js"
import style0 from "./Side.vue?vue&type=style&index=0&id=bd4e4f80&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd4e4f80",
  null
  
)

export default component.exports
import axios from "axios";

// const BaseDomin = "http://localhost:8001/"
const BaseDomin = "https://api.natega-sinai.com/"


let Api_Server = `${BaseDomin}api`

let  Api = axios.create({
  baseURL: Api_Server, 

});
export let  Base = axios.create({
  baseURL: BaseDomin ,

});




Api.defaults.withCredentials = true;
Base.defaults.withCredentials = true;

export default Api;



// let BaseApi = axios.create({
//   baseURL: "http://localhost:8000/api"
// });

// let Api = function() {
//   let token = localStorage.getItem("token");

//   if (token) {
//     BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   }

//   return BaseApi;
// };

// export default Api;

<template>
  <nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
    id="layout-navbar">
    <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
      <a class="nav-item nav-link px-0 me-xl-4" @click="addCalss" href="javascript:void(0)">
        <i class="bx bx-menu bx-sm"></i>
      </a>
    </div>

    <div class="navbar-nav-left d-flex justify-content-between" id="navbar-collapse">
      <!-- Search -->
      <div class="navbar-nav align-items-center">
        <div class="nav-item d-flex align-items-center">
         
          <div class="mt-2 d-flex align-items-center">
            <div>
              <h4 class="text-danger">
                <img :src="'../img/com.jpg'" alt class="bx img-siz h-auto " style="width: 46px;" />
                {{ time }}
              </h4>

            </div>
       
          </div>
        </div>
      </div>
         <h4 class="d-none d-md-block" style="position: relative;padding-top: 26px;">
          {{ admin.school_name }}
          </h4>
      <!-- <div > -->
       
        <!-- <vue-marquee-slider
        style="direction:ltr;"
          id="marquee-slider"
          :speed="10000"
          :width="50"
        >
 <h5>To address ;lk;k; ;lk;k;lk oiuouoiu iuyiuyiuy tyrytrytr  </h5>
</vue-marquee-slider> -->
            <!-- </div> -->
      <!-- /Search -->
      <div class="d-flex align-items-center">
        <ul class="navbar-nav flex-row align-items-center ms-auto">
          <!-- Place this tag where you want the button to render. -->
          <li class="nav-item navbar-dropdown dropdown-user dropdown">
            <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
              <div class="avatar avatar-online">

                <b-icon icon="bell" font-scale="2"></b-icon>
              </div>
            </a>
            <!-- <button type="button" @click="Logout" class="btn btn-sm rounded-pill btn-outline-danger">تسجيل الخروج</button> -->
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <span class="text-center">قريباً</span>
              </li>

            </ul>
          </li>

          <!-- User -->
    <!-- User -->
    <li class="nav-item navbar-dropdown dropdown-user dropdown">
                <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
                  <div class="avatar avatar-online">
                    <img :src="'img/user.png'" alt class="w-px-40 h-auto rounded-circle" />
                  </div>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a class="dropdown-item" href="#">
                      <div class="d-flex">
                        <div class="flex-shrink-0 me-3">
                          <div class="avatar avatar-online">
                            <img :src="'img/user.png'" alt class="w-px-40 h-auto rounded-circle" />
                          </div>
                        </div>
                        <div class="flex-grow-1">
                          <span class="fw-semibold d-block">{{admin.name}}</span>
                          <small class="text-muted">{{admin.admin == 1 ? 'Admin' : 'User'}}</small>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="dropdown-divider"></div>
                  </li>
                  <!-- <li>
                    <a class="dropdown-item" href="#">
                      <i class="bx bx-user me-2"></i>
                      <span class="align-middle">My Profile</span>
                    </a>
                  </li> -->
                  <!-- <li>
                    <a class="dropdown-item" href="#">
                      <i class="bx bx-cog me-2"></i>
                      <span class="align-middle">Settings</span>
                    </a>
                  </li> -->
        
                  <li>
                    <div class="dropdown-divider"></div>
                  </li>
                  <li>
                    <a @click="Logout" class="dropdown-item" href="#">
                      <i class="bx bx-power-off me-2"></i>
                      <span class="align-middle">Log Out</span>
                    </a>
                  </li>
                </ul>
              </li>
              <!--/ User -->

          <!--/ User -->
        </ul>
        <ul class="navbar-nav flex-row align-items-center ms-auto">
          <!-- Place this tag where you want the button to render. -->
          <li class="nav-item navbar-dropdown dropdown-user dropdown">
            <!-- <a class="nav-link dropdown-toggle hide-arrow"  href="javascript:void(0);" data-bs-toggle="dropdown">
                  <div class="avatar avatar-online">
                    <img :src="'../assets/img/avatars/1.png'" alt class="w-px-40 h-auto rounded-circle" />
                  
                  </div>
                </a> -->
            <!-- <button type="button" @click="Logout" class="btn btn-sm rounded-pill btn-outline-danger">تسجيل
              الخروج</button> -->

          </li>

          <!-- User -->


          <!--/ User -->
        </ul>

      </div>
    </div>
  </nav>
</template>

<script>
// import moment from 'moment';
import moment from 'moment-timezone';
import Cookies from "js-cookie";
import User from '@/apis/User';

export default {
  data() {
    return {
      time: ''
    }
  },

  created() {
    setInterval(() => {
      return this.time = moment().tz("Africa/Cairo").format("A hh:mm")

    }, 1000);


  },


  computed: {

admin() {
  return this.$store.state.user;
}
},
  methods: {
    Logout() {
      User.logout().then(res => {

        localStorage.removeItem("user")
        localStorage.removeItem("auth")
        localStorage.removeItem("token")
        this.$toastr.s("تم تسجيل الخروج واعادة التوجية الى صفحة الدخول", "SUCCESS MESSAGE")
        this.$router.push({ path: "/login" })
      })


      // Cookies.remove('XSRF-TOKEN')
      // // Cookies.remove('laravel_session')

      // this.$router.push({ path: "/login" })

    },
    addCalss() {

      document.getElementsByTagName("html")[0].classList.add('light-style');
      document.getElementsByTagName("html")[0].classList.add('layout-menu-fixed');
      document.getElementsByTagName("html")[0].classList.add('layout-menu-expanded');
    },


  },


}
</script>

<style lang="scss" scoped></style>
import  {Base}  from "./Api";
import Cookie from "js-cookie";

export default {
  getCookie() {
    let token = Cookie.get("XSRF-TOKEN");
   



    if (token) {
      return new Promise(resolve => {
        resolve(token);
        
      });
    }
    
  
    return Base.get("sanctum/csrf-cookie")
  }
};

import Vue from "vue";
import store from "../store/index";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Cookies from "js-cookie";
import { RouterTabRoutes } from 'vue-router-tab'
Vue.use(VueRouter);

const routes = [
 

 
 
 
  
  {
    path: '/UserSite',
    component: () => import('@/components/layout/mainUserSite.vue'),
    meta: { authOnly: true },
    children: [
      { path: '/ShowOrder', component: () => import('@/components/Documents/ShowArchiveSite.vue') },
      
      
      
    ]
  },
 
 
 

  {
    path: '/Warehouse_Management',
    component: () => import('@/components/layout/mainCustody.vue'),
    
    children: [
  
      { path: '/UploadFile', component: () => import('@/components/Documents/FileUpload') },
      { path: '/UploadImg', component: () => import('@/components/Documents/UploadImg.vue') },
      { path: '/FollowDocuments', component: () => import('@/components/Documents/FollowDocuments.vue') },
      
    ]
  },
 
  
 
  
  
 
  {
    path: '/Settings',
    component: () => import('@/components/layout/mainSettings.vue'),
 
    children: [
      { path: '/NewSite', component: () => import('@/components/Settings/newSite.vue') },
      { path: '/Showdep', component: () => import('@/components/Settings/Showdep.vue') },
      { path: '/NewUser', component: () => import('@/components/Settings/User/NewUser.vue') },
      
      { path: '/ShowUser', component: () => import('@/components/Settings/User/ShowUser')  },
    ]
  },
  {
    path: '/USChool',
    component: () => import('@/components/layout/mainSettingsUserSchool.vue'),
 
    children: [
      
      { path: '/NewUser_school', component: () => import('@/components/Settings/UserSchool/NewUser.vue') },
      
      { path: '/ShowUser_school', component: () => import('@/components/Settings/UserSchool/ShowUser')  },
      { path: '/Change_password', component: () => import('@/components/Settings/UserSchool/Changepassword')  },
      { path: '/filterData_admin',   component: () => import('@/components/Settings/UserSchool/FilterData.vue') },
      { path: '/RegistrationStatus',   component: () => import('@/components/Settings/UserSchool/RegistrationStatus.vue') },
    ]
  },
  {
    path: '/Teacher',
    component: () => import('@/components/layout/mainTeachers.vue'),
 
    children: [
      
      { path: '/Teachers',  name: "StaffList", component: () => import('@/components/EmpSchool/EmpList.vue') },
      { path: '/workers',  name: "StaffList1", component: () => import('@/components/EmpSchool/Workers.vue') },
      { path: '/filterData',   component: () => import('@/components/Settings/UserSchool/FilterData.vue') },
      
      // { path: '/ShowUser_school', component: () => import('@/components/Settings/UserSchool/ShowUser')  },
      // { path: '/Change_password', component: () => import('@/components/Settings/UserSchool/Changepassword')  },
    ]
  },
  {
    path: '/Message',
    component: () => import('@/components/layout/mainMessage.vue'),
 
    children: [
      { path: '/Message', component: () => import('@/components/Message/index.vue') },
      { path: '/CreateMessage', component: () => import('@/components/Message/CreateMessage.vue') },
      
    ]
  },
  
 
  {
    path: '/PrintPage',
    component: () => import('@/components/layout/mainPrint.vue'),
 
    children: [
      { path: '/PrintPage', component: () => import('@/components/Print/PrintPage') },
    ]
  },
  
  
  
  // {
  //   path: "/",
  //   name: "Index",

  //   component: () => import('@/views/Index.vue'),
   
  // },
  {
    path: "/",
    name: "Home",

    component: () => import('@/views/Home.vue'),
    meta: { authOnly: true , role:true}
  },
 
  {
    path: '/Login',
    component: () => import('@/views/Login.vue'),
    meta: { guestOnly: true , role:true}
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { guestOnly: true , role:true}
  },

  {
    path: '*',
    component: () => import('@/views/Error404.vue')
  },
  {
    path: '/404',
    component: () => import('@/views/Error404.vue')
  },
  {
    path: '/resultError',
    component: () => import('@/views/Error404.vue')
  }

  
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  store,
  mode: 'history',
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active"
});

function isLoggedIn() {
  return localStorage.getItem("token");
  
 
}
// function isUser() {
//  return JSON.parse(localStorage.getItem("user"));
  
 
// }
// function tokenget() {
//   // return Cookies.get('XSRF-TOKEN')
//   console.log(Cookies.get('XSRF-TOKEN'));
//   // Cookies.remove('XSRF-TOKEN')
// }

router.beforeEach((to, from, next) => {

  // const admin = isUser().admin
  // const role = to.meta.role
  // const name = to.name
  // console.log(admin);
  if (to.matched.some(record => record.meta.authOnly  )) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
   
     
      next({
        path: "/login"
        // query: { redirect: to.fullPath },lo
      });
    // } else if(!role) {
    
    //   // alert('lkjlkjlkjlkjlkj')
    //   next({
    //     path: "/404"
    //     // query: { redirect: to.fullPath },lo
    //   });
      
      
    }else{
      next();

    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn()) {
      next({
        path: "/",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }




  // if (to.matched.some(record => record.path  )) {

  //   if (isUser()) {
  //     const admin = isUser().role
  //     // console.log(to.path);
  //         if (JSON.parse(admin).some(item => item.name == to.path)) {
  //         //  console.log(item.name);
  //         next();
  //         }else{
  //           next({
  //             path: "/404"
          
  //           });
          
  //         }
  //   }else{
  //     next();
  //   }
  // }else{
  //   // next({
  //   //   path: "/404"
  
  //   // });
  // }
});



export default router;

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VueToastr from 'vue-toastr'
import VueCurrencyFilter from 'vue-currency-filter'



import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'
// Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.use(DatePicker);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


Vue.config.productionTip = false

import 'vue-instant/dist/vue-instant.css'
import VueInstant from 'vue-instant/dist/vue-instant.common'
Vue.use(VueInstant)
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
// register globally
Vue.component('multiselect', Multiselect)

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);



import VueBarcode from '@chenfengyuan/vue-barcode';

Vue.component(VueBarcode.name, VueBarcode);
import InputTag from 'vue-input-tag'
Vue.component('input-tag', InputTag)


Vue.use(RouterTab)
Vue.use(VueCurrencyFilter,
  {
    symbol: 'ج.م',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
  })

Vue.use(VueToastr, {
  defaultTimeout: 2000,
  defaultProgressBar: false,
  defaultProgressBarValue: 0,
  // defaultType: "error",
  defaultPosition: "toast-bottom-left",
  defaultCloseOnHover: false,
  // defaultStyle: { "background-color": "red" },
  defaultClassNames: ["animated", "zoomInUp"]
})
Vue.config.productionTip = false
import { vMaska } from "maska"

import VueHtmlToPaper from 'vue-html-to-paper';


const options = {
  name: '_balnk',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    // 'https://cdn.rtlcss.com/bootstrap/v4.5.3/css/bootstrap.min.css',
    // 'https://unpkg.com/kidlat-css/css/kidlat.css'
    '/assets/css/rtl.css',
    '/assets/css/print.css',
    // './assets/css/table.css'
    '/assets/vendor/css/core.css'

  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: false, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);


Vue.directive("maska", vMaska)

// import Echo from 'laravel-echo';
// import axios from 'axios'
// import Api from './apis/Api'


// window.Pusher = require('pusher-js');
// // window.io = require('socket.io-client');

// const headers = {
//     'Content-Type': 'application/json',
//     Authorization: window.localStorage.getItem('token')
//       ? `Bearer ${window.localStorage.getItem('token')}`
//       : '',
//     'Access-Control-Allow-Credentials': true,
//   };


// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'AADDK4125552HJH',
//     wsHost: 'localhost',
//     // wssHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
//     wsPort: 6001,
//     wssPort: 6001,
//     cluster:'mt1',
//     forceTLS: false,
//     // disableStats: true,
//     // forceTLS: true,
//     // authEndpoint: 'http://41.129.128.162:8000/api/broadcasting/auth',
//     authorizer: (channel, options) => {
//       return {
//         authorize: (socketId, callback) => {
//           Api
//             .post(
//               '/broadcasting/auth',
//               {
//                 socket_id: socketId,
//                 channel_name: channel.name,
//               },
//               { headers, withCredentials: true }
//             )
//             .then((response) => {
//               callback(false, response.data);
//             })
//             .catch((error) => {
//               callback(true, error);
//             });
//         }
//       }
//     }
      


 

// });



new Vue({
  router,

  LinkActiveClass: 'exact-active',
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

<template>
  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
    <div class="app-brand demo">
      <a href="/" class="app-brand-link">
        <span class="app-brand-logo demo">
          <img :src="'../img/com.jpg'" alt class="bx img-siz h-auto " />
        </span>
        <span class="app-brand-text demo menu-text fw-bolder ms-2" style="font-size:23px"> التربية والتعليم</span>
      </a>

      <a href="javascript:void(0);" @click="removeClass" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
        <i class="bx bx-chevron-left bx-sm align-middle"></i>
      </a>
    </div>

    <div class="menu-inner-shadow " style="display: block;"></div>


    <ul class="menu-inner py-1 overflow-auto">
   
      <!-- Dashboard -->
      <li class="menu-item">
        <router-link to="/" class="menu-link" @click="removeClass">
          <i class="menu-icon tf-icons bx bx-home-circle"></i>
          <div data-i18n="Analytics">الشاشة الرئيسية</div>

          <!-- <a href="index.html" class="menu-link">
              </a> -->

        </router-link>
      </li>

    <li class="menu-item open"  v-for="(name , i) in item"  >
      <a href="javascript:void(0);" class="menu-link menu-toggle"  @click="toggleClass(i)">
        <i class="menu-icon tf-icons bx bx-layout"></i>
        <div data-i18n="Layouts"  >{{ name.name }}</div>
      
          

      </a>
 
      <ul class="menu-sub">
        <li class="menu-item" v-for="nameLinke in name.linkHead" >
          <router-link :to="nameLinke.path" class="menu-link" :class="nameLinke.class"  >

        <div data-i18n="Basic Inputs" @click="removeClass" >{{ nameLinke.name }} </div>
        
        </router-link>
        </li>

      </ul>
    </li>
    <hr>
    <li class="menu-item open"  v-for="(name , i) in listAdmin" v-if="name.admin == admin.listAdmin" >
          <a href="javascript:void(0);" class="menu-link menu-toggle"  @click="toggleClass(i)">
            <i class="menu-icon tf-icons bx bx-layout"></i>
            <div data-i18n="Layouts" >{{ name.name }}</div>
          </a>
     
          <ul class="menu-sub">
            <li class="menu-item" v-for="nameLinke in name.linkHead" >
              <router-link :to="nameLinke.path" class="menu-link" >
    
            <div  data-i18n="Basic Inputs"  @click="removeClass">{{ nameLinke.name }} </div>
            </router-link>
            </li>
    
          </ul>
        </li>
</ul>

  </aside>
</template>

<script>
export default {
  props: ['item' , 'title','listAdmin'],
  data() {
    return {
      open: false,
    
    }
  },
computed: {
  admin(){
    return  this.$store.state.user
  },
},
mounted () {
  this.$store.dispatch("SetData");
},

  methods: {
    toggleClass(i) {
       this.open = i 
    },
    removeClass(){
        document.getElementsByTagName("html")[0].classList.remove('layout-menu-expanded')
      }
  },
}
</script>

<style lang="css" scoped>
.exact-active {
  background-color: #E7E7FF;
  border-left: 4px solid #696cff;
}
/* .backadmin{
  background-color: #fcbc4c !important;
    color: white !important;
} */
.bg-menu-theme .menu-item.open:not(.menu-item-closing) > .menu-toggle, .bg-menu-theme .menu-item.active > .menu-link {
    color: #ffffff !important;
    background-color: #a467f5 !important;
}
.img-siz {
  width: 40px !important;
}
</style>
import User from '@/apis/User';
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from "vuex-persist";
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:null,
    site:[],
  },
  plugins: [new VuexPersistence().plugin],
  getters: {
    get_user(state) {
      return state.user;
    },
    get_Site(state) {
      return state.site;
    }
  },
  mutations: {
    SET_USER_DATA(state , data){
      state.user = data
    },
    SET_SITE_DATA(state , data){
      state.site = data
    }
  },
  actions: {
    SetData({commit} , payload){
      User.auth().then(response => {
      
   
          commit('SET_USER_DATA' , response.data)
     
        
      }).catch(error => {
        if (error.response.status === 401) {
       
          localStorage.clear();
        router.push({path:'/login'})
          console.log('no');
        }
      })
    },
    SetDataSite({commit} , payload){

      commit('SET_SITE_DATA' , payload)
    }
  },
  modules: {
  }
})

<template>

    <div class="container" dir="ltr">
  
      <div class="row">


        <div class="col-lg-6">
          <div class="">
            <div class="">
              <!-- <h1>Manage Your Business Account</h1> -->
              <!-- <p>Donec dictum nisl nec mi lacinia, sed maximus tellus eleifend. Proin molestie cursus sapien ac eleifend.</p> -->
              <img :src="'img/com.jpg'" alt="" />
            </div>
          </div>

        </div>
          <div class="col-lg-6">
            <!---728x90--->
            <h2 style="padding-top: 90px;">أدخل بياناتك للدخول إلى حسابك</h2>
            <!-- <p>Enter your details to login.</p> -->
            <form >
              <label> User Name</label>
              <div class="input-group">
                <span class="fa fa-envelope" aria-hidden="true"></span>
                <input v-model="form.email" type="text" placeholder="Enter Your user Name" required="">
                <span class="text-danger" v-if="errors.email">
                  {{ errors.email[0] }}
                </span>
              </div>
              <label>Password</label>
              <div class="input-group">
                <span class="fa fa-lock" aria-hidden="true"></span>
                <input v-model="form.password" type="Password" placeholder="Enter Password" required="">
                <span class="text-danger" v-if="errors.password">
                  {{ errors.password[0] }}
                </span>
              </div>
              <!-- <div class="login-check">
						 <label class="checkbox"><input type="checkbox" name="checkbox" checked=""><i> </i> Remember me</label>
					</div>		
          				 -->
                   <b-overlay
                        :show="busy"
                        rounded
                        opacity="0.6"
                        spinner-small
                        spinner-variant="primary"
                        class="d-inline-block"
                     
                        >
                        <b-button
                            ref="button"
                            :disabled="busy"
                            variant="primary"
                            @click="login"
                        >
                           دخول
                        </b-button>
                        </b-overlay>
              <!-- <button class="btn btn-danger btn-block" type="submit">دخول</button> -->
            </form>
            <!-- <p class="account">By clicking login, you agree to our <a href="#">Terms & Conditions!</a></p>
				<p class="account1">Dont have an account? <a href="#">Register here</a></p> -->
          </div>
        <!-- //main content -->
      </div>
      <!---728x90--->
      <!-- footer -->
      <div class="footer">
        <!-- <p>&copy; 2023 All Rights Reserved </p> -->
      </div>
      <!-- footer -->
    </div>


  <!-- <div class="row">
    <div class="d-flex justify-content-center mb-3" v-if="loadingPage">
    <b-spinner label="Loading..."></b-spinner>
  </div>
  <div class="home  mx-auto py-5 mt-5" v-if="!loadingPage">
    <h3 class="text-center">تسجيل الدخول الى النظام</h3>
    <div class="card">
      <div class="card-body">
        <div class="form-group">
          <label for="email">اسم المستخدم:</label>
          <input type="email" v-model="form.email" class="form-control" id="email" />
          <span class="text-danger" v-if="errors.email">
            {{ errors.email[0] }}
          </span>
        </div>
        <div class="form-group mt-4">
          <label for="password">كلمة المرور:</label>
          <input type="password" v-model="form.password" class="form-control" id="password" />
          <span class="text-danger" v-if="errors.password">
            {{ errors.password[0] }}
          </span>
        </div>
        <button @click.prevent="login" class="btn btn-primary btn-block mt-4">
          تسجيل الدخول
        </button>
      </div>
    </div>
  </div>
</div> -->
</template>

<script>
import User from '@/apis/User';
import Api from '@/apis/Api';
import jsCookie from 'js-cookie';


export default {
  data() {
    return {
      loadingPage: false,
      busy: false,
      form: {
        email: "",
        password: "",
        device_name: "browser"
      },
      errors: []
    }
  },
  mounted() {
    sessionStorage.clear();
    localStorage.clear();
    jsCookie.remove('laravel_session')
  },
  methods: {

    login() {
      this.busy = true
      this.loadingPage = true
      User.login(this.form).then(response => {
        this.$root.$emit("login", true);
        localStorage.setItem("token", true);
        localStorage.setItem("auth", "true");
        this.busy = true
        User.auth().then(response => {

          this.user = response.data;
          localStorage.setItem("user", JSON.stringify(response.data));
          // localStorage.setItem("user", JSON.stringify(response.data));
          // this.show=false
        
        })
        this.$store.dispatch("SetData", this.user)
        Api.get('/site').then(res => {
          // this.sites = res.data
          this.$store.dispatch("SetDataSite", res.data)
        });
        this.$router.push({ name: "Home" });
      }).catch(error => {
        if (error.response.status === 422) {
          this.busy = false
          this.errors = error.response.data.errors;
        }
      })
    },

  

    

  }
}
</script>
<style lang="css" scoped>
img {
  width: 100%;
}

/*-- //Reset-Code --*/

.signupform {
  padding: 3em 0;
}



input[type="text"] {
  font-size: 15px;
  color: #333;
  text-align: left;
  letter-spacing: 1px;
  padding: 14px 10px;
  width: 93%;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  outline: none;
  background: transparent;
  font-family: 'Raleway', sans-serif;
}

input[type="Password"] {
  font-size: 15px;
  color: #333;
  text-align: left;
  text-transform: capitalize;
  letter-spacing: 1px;
  padding: 14px 10px;
  width: 93%;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  outline: none;
  background: transparent;
  font-family: 'Raleway', sans-serif;
}

.input-group {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 3px 10px;
  border: 1px solid #ddd;
  background: #fff;
}

.btn-block {
  background: #ff4040;
  border: none;
  color: #fff;
  font-size: 13px;
  padding: 12px 40px;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
}


.input-group span.fa {
  font-size: 16px;
  vertical-align: middle;
  box-sizing: border-box;
  float: left;
  text-align: center;
  width: 6%;
  padding: 15px 0px;
  color: #01cd74;
}




</style>
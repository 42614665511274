<template>
  <!-- <div id="app" class="layout-wrapper layout-content-navbar"> -->
    <div class="layout-container">
      <div class="layout-page">
        <Nav></Nav>
        <div class="content-wrapper" >
          <div class="container-xxl flex-grow-1 container-p-y">

            <div class="row">
      
              <!-- <div class="col-12">
                <table class="table table-sm" >
                  <thead class="table-dark">
                    <tr>

                      <th>الموقع</th>

                      <th>مسئول الموقع </th>
                      <th>محاسب الموقع </th>
                      <th>اسم المستخدم </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ admin.site?.site_name }}</td>

                      <td>{{ admin.site?.mange_name }}</td>
                      <td>{{ admin.site?.account_name }}</td>
                      <td>{{ admin.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
              <b-overlay :show="show" rounded="sm">
                <b-alert show variant="danger" v-if="admin.change_password == 0" >تنبية : يجب عليك أولأ تغير كلمة المرور حتى يتم تفعيل الخدمات المتاحة <strong>
                  <router-link to="Change_password"  >
                    إضغط هنا للتغير
                    </router-link>
                
                  
                  </strong> </b-alert>
                <div class="d-flex flex-wrap justify-content-center">
                 
                  <!-- <div class="col-4 my-4 text-center">
                    <h4>شركة تارجت للمقاولات</h4>
                  </div>
                  <div class="col-4 my-4 text-center">
                    <img :src="'../img/com.jpg'" alt class="bx img-siz h-auto " />
                  </div>
                  <div class="col-4 my-4 text-center" style="z-index: -10000;">

                    <h4>المهندس / احمد جمال حمدي</h4>
                    
                  </div> -->
                 
                 
                 

                  <div class="card icon-card cursor-pointer text-center mb-4 mx-2" v-if="admin.site_code">
                    <router-link to="UserSite" class="menu-link">
                      <div class="card-body">


                        <img :src="'../img/site.png'" alt class="bx img-siz h-auto " />
                        <p class="icon-name text-capitalize text-truncate mb-0"> سجل اﻻرشيف </p>
                      </div>
                    </router-link>
                  </div>

               
                  <div class="card icon-card cursor-pointer text-center mb-4 mx-2" v-if="admin.hr">
                    <router-link to="Employees" class="menu-link">
                      <div class="card-body">


                        <img :src="'../img/employees.gif'" alt class="bx img-siz h-auto " />
                        <p class="icon-name text-capitalize text-truncate mb-0">خدامات الموطنين</p>
                      </div>
                    </router-link>
                  </div>
                
                  <div class="card icon-card cursor-pointer text-center mb-4 mx-2" v-if="admin.report">
                    <router-link to="Reports" class="menu-link">
                      <div class="card-body">


                        <img :src="'../img/report.png'" alt class="bx img-siz h-auto " />
                        <p class="icon-name text-capitalize text-truncate mb-0">التقارير </p>
                      </div>
                    </router-link>
                  </div>
                  
                 
                  <div class="card icon-card cursor-pointer text-center mb-4 mx-2" v-if="admin.settings">
                    <router-link to="Settings" class="menu-link">
                      <div class="card-body">

                        <img :src="'../img/settings.png'" alt class="bx img-siz h-auto " />

                        <p class="icon-name text-capitalize text-truncate mb-0"> الضبط العام </p>
                      </div>
                    </router-link>
                  </div>
                  <div class="card icon-card cursor-pointer text-center mb-4 mx-2" v-if="admin.school">
                    <router-link to="USChool" class="menu-link">
                      <div class="card-body">

                        <img :src="'../img/settings.png'" alt class="bx img-siz h-auto " />

                        <p class="icon-name text-capitalize text-truncate mb-0"> الضبط العام </p>
                      </div>
                    </router-link>
                  </div>
                 
                 
                  <div class="card icon-card cursor-pointer text-center mb-4 mx-2" v-if="admin.message">
                    <!-- <span class="badge badge-center rounded-pill bg-danger">4</span> -->
                    <router-link to="Message" class="menu-link">
                      <div class="card-body">
                        <!-- <span v-if="count" class="badge bg-danger"
                          style="position: absolute;margin-right: 39px;;margin-top: -18px"> {{ count }} رسالة لك </span> -->
                        <img :src="'../img/communications.gif'" alt class="bx img-siz h-auto " />
                        <p class="icon-name text-capitalize text-truncate mb-0">المراسلات </p>
                      </div>
                    </router-link>
                  </div>
                 
                  <div class="card icon-card cursor-pointer text-center mb-4 mx-2" v-if="admin.archive == 1">
                    <!-- <span class="badge badge-center rounded-pill bg-danger">4</span> -->
                    <router-link to="Warehouse_Management" class="menu-link">
                      <div class="card-body">

                        <img :src="'../img/upload.gif'" alt class="bx img-siz h-auto " />
                        <p class="icon-name text-capitalize text-truncate mb-0"> اﻻرشيف العام </p>
                      </div>
                    </router-link>
                  </div>
                  <div class="card icon-card cursor-pointer text-center mb-4 mx-2"  v-if="admin.change_password == 1">
                    <!-- <span class="badge badge-center rounded-pill bg-danger">4</span> -->
                    <router-link to="Teachers"  class="menu-link" >
                      <div class="card-body">

                        <img :src="'../img/pre.gif'"  alt class="bx img-siz h-auto " />
                        <p class="icon-name text-capitalize text-truncate mb-0">العاملين بالحصة</p>
                      
                      </div>
                       
                    </router-link>
                  </div>
                  <!-- <div class="card icon-card cursor-pointer text-center mb-4 mx-2"  v-if="admin.admin">
                    <router-link to="NOTIFICATIONS"  class="menu-link" >
                      <div class="card-body">

                        <img :src="'../img/noti.png'"  alt class="bx img-siz h-auto " />
                        <p class="icon-name text-capitalize text-truncate mb-0">اشعارات واردة</p>
                      
                      </div>
                       
                    </router-link>
                  </div> -->
                  <!-- <div class="card icon-card cursor-pointer text-center mb-4 mx-2" v-if="admin.admin">
                   
                    <router-link to="Ticket" class="menu-link">
                      <div class="card-body">

                        <img :src="'../img/ticket.png'" alt class="bx img-siz h-auto " />
                        <p class="icon-name text-capitalize text-truncate mb-0">Open Ticket</p>
                      </div>
                    </router-link>
                  </div> -->
                  <!-- <div class="card icon-card cursor-pointer text-center mb-4 mx-2" v-if="admin.admin">
                  
                    <router-link to="PrintPage" class="menu-link">
                      <div class="card-body">

                        <img :src="'img/printer.gif'" alt class="bx img-siz h-auto " />
                        <p class="icon-name text-capitalize text-truncate mb-0">مطبوعات</p>
                      </div>
                    </router-link>
                  </div> -->
                  <!-- <div class="card icon-card cursor-pointer text-center mb-4 mx-2" v-if="admin.admin">
                    <router-link to="UploadFile" class="menu-link">
                      <div class="card-body">

                        <img :src="'img/document.gif'" alt class="bx img-siz h-auto " />
                        <p class="icon-name text-capitalize text-truncate mb-0">Documents</p>
                      </div>
                    </router-link>
                  </div> -->
                  
                </div>
              </b-overlay>

            </div>
            <!-- start Message -->



            <!-- end Message -->
          </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>

import Nav from "@/components/layout/Nav.vue";

import User from '@/apis/User';
import Cookies from "js-cookie";
import Api, { Base } from '@/apis/Api';

export default {
  components: {
  
    Nav,

  },
  data() {
    return {
    
      
      count: '',
      ShowMessage: '',
      show: false
    }
  },
  computed: {
    admin() {
      return this.$store.getters.get_user;
    },
  },
  created () {
    this.$store.dispatch("SetData")
  },
  mounted() {
  
        document.getElementsByTagName("html")[0].classList.remove('layout-menu-expanded')
    
        

        // Echo.join('presence-chat')
        // .here((users) => {
        //     console.log('Users currently online:', users); 
        //     // Update your UI to display online users
        // })
        // .joining((user) => {
        //     console.log('User joined:', user);
        //     // Update your UI to show a user joined
        // })
        // .leaving((user) => {
        //     console.log('User left:', user);
        //     // Update your UI to show a user left
        // }); 

        
    // Api.get('count_message').then(res => { this.count = res.data })
    // Api.get('Show_message_in_screen').then(res => {
    //   if (res.data.length) {

    //     this.$swal({
    //       title: `${res.data['0'].name}`,
    //       text: `${res.data['0'].body}`,
  
  
    //       confirmButtonText: 'عدم عرض الرسالة',
  
    //     }).then((result) => {
    //       /* Read more about isConfirmed, isDenied below */
    //       if (result.isConfirmed) {
  
    //         Api.get('/closeMessageId/' + res.data['0'].id).then(res => {
  
    //         }).catch(e => { });
  
    //       } else if (result.isDenied) {
    //         this.$swal('Changes are not saved', '', 'info')
    //       }
    //     })
    //   }


    // })


  },
}
</script>

<style lang="css" scoped>
.bx {
  font-size: 6.15rem !important;
}

.img-siz {
  width: 90px !important;
}
</style>